<template>
  <v-app id="inspire">
    <v-content>
      <CandidateSignup @signupDone="authenticate" />

      <v-dialog
        v-model="successDialog"
        persistent
        width="500"
      >
        <v-card>
          <v-card-title
            class="headline light-green lighten-2"
            primary-title
          >
            <v-icon style="margin-right: 10px">
              check_circle
            </v-icon>
            ¡Hemos recibido tus datos exitosamente!
          </v-card-title>

          <v-card-text>
            <div class="body-1">
              Ya puedes empezar a postular en
              <a
                :href="production_site"
                @click.stop="redirectToSite"
              >
                nuestro portal de ofertas.
              </a>
            </div>
          </v-card-text>

          <v-divider />

          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              flat
              :href="production_site"
              @click.stop="redirectToSite"
            >
              Ver ofertas laborales
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-content>
  </v-app>
</template>

<script>
import axios from 'axios';
import { parseJwt } from '@/utils/jwt';
import JWTConfigMixin from '@/components/mixins/JWTConfigMixin.vue';
import CandidateSignup from '@/components/forms/CandidateSignup.vue';

export default {
  name: 'EmbedRegister',
  components: {
    CandidateSignup,
  },
  mixins: [JWTConfigMixin],
  data() {
    return {
      errorchip: false,
      passErrors: [],
      userErrors: [],
      chipMsg: '',
      successDialog: false,
      production_site: 'https://empleos.mandomedio.com/',
    };
  },
  methods: {
    authenticate(payloadLogin) {
      this.$store.commit('setLoading');

      this.errorchip = false;
      this.passErrors = [];
      this.userErrors = [];

      axios.post(this.$store.state.endpoints.obtainJWT, payloadLogin)
        .then(response => {
          this.$store.commit('updateToken', response.data.token);
          this.$store.commit('setAdmin', parseJwt(this.$store.state.jwt).is_admin);
          if (!this.$store.state.isAdmin) {
            axios.get(this.$store.state.endpoints.candidates.getLoginData, this.jwtConfig)
              .then(resp => {
                this.$store.commit('setLoginDetails', resp.data);
                this.$store.commit('unsetLoading');

                this.successDialog = true;
              });
          } else {
            this.$store.commit('setAdminName', parseJwt(this.$store.state.jwt).username);
            this.$store.commit('unsetLoading');
          }
        })
        .catch(error => {
          this.$store.commit('unsetLoading');
          if (error.response.data.non_field_errors) {
            this.errorchip = true;
            this.chipMsg = error.response.data.non_field_errors.pop();
          } else {
            if (error.response.data.username) {
              this.userErrors = [...error.response.data.username];
            }
            if (error.response.data.password) {
              this.passErrors = [...error.response.data.password];
            }
          }
        });
    },
    redirectToSite() {
      window.parent.location.href = this.production_site;
    },
  },
};
</script>
